<template lang="">
  <my-container>
    <v-row>
      <v-col>
        <h3>Dane personalne</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p><b>Imię i nazwisko: </b>{{ client.fullname }}</p>

        <p><b>Telefon: </b>{{ client.phone }}</p>

        <p><b>E-mail: </b>{{ client.email }}</p>

        <p><b>Adres: </b>{{ client.address }}, {{ client.city }} {{ client.zipCode }}</p>
      </v-col>
    </v-row>
    <v-row v-if="isOffice">
      <v-col>
        <p><primary-button :onClick="openModal">Edytuj</primary-button></p>
      </v-col>
    </v-row>
    <confirm-modal
      @decline="declineEdit"
      @accept="acceptEdit"
      title="Edytuj dane personalne"
      :open="isModalEditOpen"
      agree="Zapisz"
      disagree="Anuluj"
    >
      <v-form v-model="isFormValid" ref="form">
        <v-row>
          <v-col>
            <v-text-field
              v-model="editingClient.firstName"
              label="Imię"
              :rules="validator.requiredRules"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="editingClient.lastName"
              label="Nazwisko"
              :rules="validator.requiredRules"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="editingClient.phone"
              :rules="validator.phoneRules"
              label="Telefon"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="editingClient.email"
              :rules="validator.emailRules"
              label="Email"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              v-model="editingClient.address"
              label="Adres"
              :rules="validator.requiredRules"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="editingClient.city"
              label="Miasto"
              :rules="validator.requiredRules"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="editingClient.zipCode"
              label="Kod pocztowy"
              :rules="validator.zipCodeRules"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </confirm-modal>
  </my-container>
</template>
<script>
import { clientGetters } from '../../../../mixins/getters';
import { validator } from '../../../../mixins/validators';
import MyContainer from '../../../MyContainer.vue';
import PrimaryButton from '../../../buttons/PrimaryButton.vue';
import ConfirmModal from '../../../modals/ConfirmModal.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'ClientPersonal',
  components: { MyContainer, PrimaryButton, ConfirmModal },
  mixins: [clientGetters, validator],
  data() {
    return {
      isModalEditOpen: false,
      editingClient: {},
      isFormValid: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['isOffice']),
  },
  methods: {
    openModal() {
      this.editingClient = { ...this.client };
      this.isModalEditOpen = true;
    },
    declineEdit() {
      this.isModalEditOpen = false;
    },
    acceptEdit() {
      this.$refs.form.validate();
      if (this.isFormValid) {
        this.updateClient(this.editingClient);
        this.isModalEditOpen = false;
      }
    },
    updateClient() {
      try {
        this.$store.dispatch('client/updateClient', {
          clientId: this.client._id,
          client: this.editingClient,
        });
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>
<style lang=""></style>
