<template lang="">
  <my-container>
    <v-row>
      <v-col cols="8"
        ><h2 class="pb-4">
          Montaż:
          <span class="text-uppercase ml-8"> {{ installationDate }}</span>
        </h2>
          <div v-if="client.lastAssemblyLog && client.isInstallationPlanned" class="grey--text text-darken-1 overline">         
            <p class="text-uppercase" v-if="isOffice">
              {{ client.lastAssemblyLog.messageForOffice }} w dniu 
              {{ formatDateWithHour(client.lastAssemblyLog.createdAt) }}
            </p>
            <p class="text-uppercase" v-else>
              {{ client.lastAssemblyLog.message }} w dniu 
              {{ formatDateWithHour(client.lastAssemblyLog.createdAt) }}
            </p>
          </div> 
          <p v-else class="text-uppercase grey--text text-darken-1 overline">Montaż nie został jeszcze zaplanowany</p>
          </v-col>
      <v-col class="text-right" >
        <p><outlined-button v-if="showOnCondition()" :onClick="openDateModal" >{{ client.installationDate ? 'Zmień termin' : 'Wybierz termin'}} </outlined-button></p>
      </v-col></v-row
    >
    <confirm-modal
      @decline="declineEdit"
      @accept="acceptEdit"
      title="Wybierz termin montażu"
      :open="isModalEditOpen"
      agree="Zapisz"
      disagree="Anuluj"
      :loading="btnDateLoading"
    >
        <v-form v-model="isFormValid" ref="form">
          <v-row justify="center"> <v-menu
            v-model="menuDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            class="mt-4 pt-4"
            v-model="pickedDate"
            label="Wybierz dzień"
            prepend-icon="mdi-calendar"
            readonly
            :rules="validator.requiredRules"
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="pickedDate"
          firstDayOfWeek="1"
          required
          @input="menuDate = false"
        ></v-date-picker>
      </v-menu>
      </v-row>
        </v-form>
    </confirm-modal>
  </my-container>
</template>
<script>
import MyContainer from '../../../components/MyContainer.vue';
import { clientGetters } from '../../../mixins/getters';
import { formatDateWithHour } from '../../../mixins/formatDate';
import OutlinedButton from '../../buttons/OutlinedButton.vue';
import ConfirmModal from '../../../components/modals/ConfirmModal.vue';
import { validator } from '../../../mixins/validators';
import { mapGetters } from 'vuex';

export default {
  name: 'ClientStatus',
  components: { MyContainer, OutlinedButton, ConfirmModal },
  mixins: [clientGetters, formatDateWithHour, validator],
  data() {
    return {
      menuTime: false,
      isModalEditOpen: false,
      btnDateLoading: false,
      pickedDate: "",
      pickedTime: "",
      isFormValid: false,
      menuDate: false
    };
  },
  computed: {
    installationDate() {
      return this.client.installationDate ? this.formatDateWithHourConditionally(this.client.installationDate) : 'Brak';
    },
    ...mapGetters('auth', ['isOffice', 'isTrader', 'isFitter']),
    ...mapGetters('static', ['clientsStatuses']),
  },
  methods: {
    showOnCondition() {
      return this.isOffice || this.isFitter && this.client.status.value === this.clientsStatuses.FITTER_ASSIGNED.value
    },
    openDateModal() {
      this.isModalEditOpen = true;
    },
    mergeInstallationDate() {
      return this.pickedDate + ' ' + this.pickedTime;
    },
    acceptEdit() {
      this.$refs.form.validate();
      if(this.isFormValid) {
        this.btnDateLoading = true;
        try {
          this.$store.dispatch('client/updateInstallationDate', {
            clientId: this.client._id,
            installationDate: this.mergeInstallationDate()
          });
          this.isModalEditOpen = false;
        } catch(e) {
          console.error(e);
        } finally {
          this.btnDateLoading = false;
        }
      }
    },
    declineEdit() {
      this.isModalEditOpen = false;
    }
  },
};
</script>
<style lang=""></style>
