<template lang="">
  <my-container>
    <v-row>
      <v-col cols="12" sm="6"
        ><h2>
          Oferta: <span class="title"> {{ offerState }}</span>
        </h2>
        <div v-if="client.lastOfferChange" class="grey--text text-darken-1 overline">
          <p class="text-uppercase" v-if="isOffice">
            {{ client.lastOfferChange.messageForOffice }}
            {{ formatDateWithHour(client.lastOfferChange.createdAt) }}
          </p>
          <p class="text-uppercase" v-else>
            {{ client.lastOfferChange.messageForUser }}
            {{ formatDateWithHour(client.lastOfferChange.createdAt) }}
          </p>
        </div>
        <div v-if="showSolitanOfferData">
          <p class="mt-4 grey--text text-darken-1 overline">
            {{ formatDateWithHour(client.solitanOffer.current.createdAt) }}
            PRZEZ
            {{ solitanOfferCreatedBy }}
          </p>
          <div>
            <v-icon class="mr-2 mb-1"> mdi-file-document-outline </v-icon>
            <span>
              <b>Oferta Solitan</b> ({{
                formatDateWithHour(client.solitanOffer.current.createdAt)
              }})
            </span>
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="6" class="text-right" v-if="showGenerateOffer">
        <p>
          <solitan-button :onClick="openSolitanWizard">Generuj w SOLITAN</solitan-button>
        </p>
        <p>
          <v-btn
            elevation="0"
            large
            outlined
            color="grey"
            v-if="generatedInSolitan"
            @click="openOfferInSolitan"
            >Otwórz w SOLITAN</v-btn
          >
        </p>
      </v-col></v-row
    >
    <v-row>
      <v-col cols="12" class="grey--text text-darken-1 overline"> </v-col>
    </v-row>
  </my-container>
</template>
<script>
import MyContainer from '../../../components/MyContainer.vue';
import { clientGetters, staticGetters } from '../../../mixins/getters';
import SolitanButton from '../../buttons/SolitanButton.vue';
import { mapGetters } from 'vuex';
import config from '../../../../configuration.json';
import { formatDateWithHour } from '../../../mixins/formatDate';
import { isEmpty } from 'lodash';

export default {
  name: 'ClientStatus',
  components: { MyContainer, SolitanButton },
  mixins: [clientGetters, staticGetters, formatDateWithHour],
  methods: {
    openSolitanWizard() {
      window.open(`${config.SOLITAN_WIZARD_URL}?org=${config.APP_NAME}&id=${this.client._id}`);
    },
    openOfferInSolitan() {
      window.open(`${config.SOLITAN_OFFER_URL}/${this.client.solitanOffer.current._id}`);
    },
  },
  computed: {
    showSolitanOfferData() {
      return this.generatedInSolitan && !this.client.isOfferAddedManually;
    },
    showGenerateOffer() {
      return (
        !this.client.isOfferAddedManually ||
        this.client.status.value === this.clientsStatuses.TRADER_ASSIGNED.value
      );
    },
    offerState() {
      const { isOfferAddedManually } = this.client;
      return isOfferAddedManually
        ? 'DODANA MANUALNIE'
        : !this.generatedInSolitan
        ? 'NIEWYGENEROWANA'
        : 'WYGENEROWANA';
    },
    generatedInSolitan() {
      return !isEmpty(this.client.solitanOffer);
    },
    solitanOfferCreatedBy() {
      const { firstName, lastName } = this.client.solitanOffer.current.createdBy;
      return `${firstName} ${lastName}`;
    },
    ...mapGetters('auth', ['isOffice', 'isTrader', 'isFitter']),
  },
};
</script>
<style lang="scss" scoped></style>
