<template lang="">
  <v-container class="ma-0 pa-0">
    <loading :loading="loading">
      <my-container>
        <v-row>
          <v-col>
            <h2>Historia wydarzeń</h2>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-virtual-scroll :bench="benched" :items="messages" height="300" item-height="48">
              <template v-slot:default="{ item }">
                  <p class="mb-0" :class="isLog(item.type) ? 'text--secondary font-italic' : 'text--primary' " >
                  <span>{{formatDateWithHour(item.createdAt)}}</span>
                  <b class="ml-2" v-if="!isLog(item.type)">{{item.user.fullname}}</b>
                  <span class="ml-2">({{mapType(item.type)}}):</span>
                  <span v-if="isOffice && item.messageForOffice"> {{item.messageForOffice}}</span>
                  <span v-else> {{item.message}}</span>
                  </p>
              </template>
            </v-virtual-scroll>
          </v-col>
        </v-row>
      </my-container>
      <v-container>
        <v-form v-model="isFormValid" ref="form">
          <v-row>
            <v-col cols="12" md="10" class="pl-0">
              <v-textarea
                class="white pl-4"
                rows="1"
                v-model="messageToSend"
                :rules="validator.chatRules"
              ></v-textarea></v-col
            ><v-col md="2" class="text-right pr-0"
              ><primary-button :loading="btnLoading" :onClick="sendMessageToServer"
                >Wyślij</primary-button
              ></v-col
            ></v-row
          >
        </v-form>
      </v-container>
    </loading>
  </v-container>
</template>
<script>
import MyContainer from '../../../components/MyContainer.vue';
import PrimaryButton from '../../buttons/PrimaryButton.vue';
import socket from 'socket.io-client';
import config from '../../../../configuration.json';
import { formatDateWithHour } from '../../../mixins/formatDate';
import { validator } from '../../../mixins/validators';
import Loading from '../../../components/Loading.vue';
import { mapGetters } from 'vuex';
import polishChat from '../../../static/polishChat';

export default {
  components: {
    MyContainer,
    PrimaryButton,
    Loading,
  },
  mixins: [formatDateWithHour, validator],
  computed: {
    ...mapGetters('auth', ['isOffice', 'isTrader', 'isFitter']),
    
  },
  data() {
    return {
      benched: 10,
      messages: [],
      messageToSend: '',
      isConnected: false,
      socketMessage: '',
      socket: null,
      roomId: '',
      isFormValid: false,
      loading: false,
      btnLoading: false,
    };
  },

  sockets: {
    connect() {
      this.isConnected = true;
    },

    disconnect() {
      this.isConnected = false;
    },

    messageChannel(data) {
      this.socketMessage = data;
    },
  },

  methods: {
    isLog(type) {
      return type === 'LOG';
    },
    mapType(type) {
      return polishChat[type];
    },
    setMessages(messages) {
      this.messages = messages;
      this.loading = false;
      this.btnLoading = false;
    },
    sendMessageToServer() {
      this.$refs.form.validate();

      if (this.isFormValid) {
        this.btnLoading = true;
        this.socket.emit('clientMessageChannel', this.messageToSend, this.roomId, this.setMessages);
        this.messageToSend = '';
        this.$refs.form.resetValidation();
      }
    },

    joinClientIdRoom() {
      this.loading = true;
      this.socket.emit('joinClientIdRoom', this.roomId, this.setMessages);
    },

    leaveClientRoomId() {
      this.socket.emit('leaveClientRoomId', this.roomId);
    },
  },

  created() {
    this.roomId = this.$route.params.clientId;
    this.socket = socket(config.WEB_SOCKET_URL, { withCredentials: true });
    this.joinClientIdRoom();
    this.isConnected = true;

    this.socket.on('receive-message-channel', (data) => {
      this.setMessages(data.logs);
    });

    setInterval(() => {
      if (this.isConnected) {
        this.socket.emit('getClientMessages', this.roomId, this.setMessages);
      }
    }, 3000);
  },

  beforeDestroy() {
    this.socket.close();
    this.leaveClientRoomId();
    this.isConnected = false;
  },
};
</script>
<style lang=""></style>
