<template>
  <v-container class="ma-0 pa-0">
    <my-container>
      <v-row
        ><v-col><h2>Pliki</h2></v-col></v-row
      >
      <v-virtual-scroll
        class="mt-4 disable-overflow"
        :bench="benched"
        :items="getClientFiles"
        max-height="300"
        item-height="48"
        width="100%"
      >
        <template v-slot:default="{ item }">
          <v-row>
            <v-col cols="1">
              <v-icon> mdi-file-document-outline </v-icon>
            </v-col>
            <v-col cols="8"
              ><a :href="item.fullPath" target="_blank" class="download-link">
                <b>{{ item.typeNameForUser }}</b> ({{ item.name }}, {{ item.mb }}MB)</a
              ></v-col
            >
            <v-col cols="2">{{ `v${item.version}` || 'v0' }}</v-col>
            <v-col cols="1" v-if="isOffice">
              <v-btn @click="() => deleteFile(item)" icon>
                <v-icon color="solitan"> mdi-delete </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </v-virtual-scroll>
      <div v-if="client.solitanOffer">
        <v-divider></v-divider>
        <v-row>
          <v-col cols="12">
            <h4 class="grey--text text-darken-2 overline py-6">
              Oferty wygenerowane w Solitan - przekierowanie
            </h4>
          </v-col>
        </v-row>
        <v-virtual-scroll
          class="mt-1 disable-overflow"
          :bench="benched"
          :items="solitanOffers"
          max-height="300"
          item-height="35"
          width="100%"
        >
          <template v-slot:default="{ item }">
            <v-row>
              <v-col cols="1">
                <v-icon> mdi-file-document-outline </v-icon>
              </v-col>
              <v-col cols="8">
                <a :href="hrefTo(item._id)" target="_blank" class="download-link"
                  ><b>Oferta Solitan</b> ({{ formatDateWithHour(item.createdAt) }})</a
                >
              </v-col>
            </v-row>
          </template>
        </v-virtual-scroll>
      </div>
      <v-row>
        <v-col cols="12" class="grey--text text-darken-1 overline"> </v-col>
      </v-row>
    </my-container>
    <v-container>
      <loading :loading="loadingSelect">
        <v-form v-model="isFormValid" ref="form">
          <v-row class="pt-6">
            <v-col class="mx-0 px-0">
              <h3 class="text--bold">Wybierz rodzaj dokumentu (wymagane)</h3>
              <v-select
                v-model="pickedFileType"
                :items="roleFileTypes"
                clearable
                solo
                label="Rodzaj wysyłanego dokumentu *"
                item-text="nameForUser"
                item-value="value"
              ></v-select>
            </v-col>
          </v-row>
          <v-row v-if="pickedFileType">
            <v-col cols="12" md="10" class="py-0">
              <v-file-input
                v-model="file"
                solo
                :rules="validator.fileRules"
                :label="`Wybierz plik (${showAcceptedExtensions}max 5mb)`"
                show-size
                :accept="this.getSelectedMediaType"
              ></v-file-input>
            </v-col>
            <v-col md="2" class="text-right pr-0 py-0"
              ><primary-button :onClick="uploadFile" :loading="loadingSendBtn"
                >Wyślij</primary-button
              ></v-col
            >
          </v-row>
        </v-form>
      </loading>
    </v-container>
  </v-container>
</template>
<script>
import MyContainer from '../../MyContainer.vue';
import { fileTypesByRoleGetter, clientGetters } from '../../../mixins/getters';
import { validator } from '../../../mixins/validators';
import { formatDateWithHour } from '../../../mixins/formatDate';
import PrimaryButton from '../../buttons/PrimaryButton.vue';
import Loading from '../../Loading.vue';
import config from '../../../../configuration.json';
import { mapGetters } from 'vuex';

export default {
  name: 'ClientStatus',
  components: { MyContainer, PrimaryButton, Loading },
  mixins: [fileTypesByRoleGetter, clientGetters, validator, formatDateWithHour],
  data() {
    return {
      isFormValid: false,
      loadingSelect: false,
      pickedFileType: '',
      file: null,
      loadingSendBtn: false,
      benched: 10,
    };
  },
  watch: {
    pickedFileType() {
      this.file = null;
    },
    'client.status': function () {
      this.getFileByRole();
    },
  },
  computed: {
    ...mapGetters('static', ['clientFileTypes']),
    ...mapGetters('auth', ['isOffice']),
    solitanOffers() {
      return this.client.solitanOffer
        ? [this.client.solitanOffer.current, ...this.client.solitanOffer.previous]
        : [];
    },
    getClientFiles() {
      return this.client.filesFull || [];
    },
    getFilesTypesByRole() {
      return this.roleFileTypes || [];
    },
    getSelectedMediaType() {
      const selectedFileType = this.getSelectedFileType();
      return selectedFileType !== undefined ? selectedFileType.mediaExtensions.join(', ') : '';
    },
    showAcceptedExtensions() {
      const selectedFileType = this.getSelectedFileType();
      if (selectedFileType) {
        return `${selectedFileType?.extensions?.join(', ')}, `;
      }
      return '';
    },
  },
  methods: {
    getSelectedFileType() {
      return this.getFilesTypesByRole.find((item) => item.value === this.pickedFileType);
    },
    getFileTypes(type) {
      return this.roleFileTypes.find((item) => item.value === type).nameForUser;
    },
    async getFileByRole() {
      this.loadingSelect = true;
      try {
        await this.$store.dispatch('static/getFileByRole', {
          status: this.client.status.value,
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingSelect = false;
      }
    },
    async uploadFile() {
      this.$refs.form.validate();
      if (this.file && this.isFormValid && this.pickedFileType) {
        this.loadingSendBtn = true;
        try {
          await this.$store.dispatch('client/uploadFile', {
            clientId: this.client._id,
            type: this.pickedFileType,
            file: this.file,
          });
          this.$toasted.global.success({
            message: 'Plik został poprawnie dodany.',
          });
          this.file = null;
          this.pickedFileType = '';
          await this.getFileByRole();
        } catch (error) {
          console.error(error);
        } finally {
          this.loadingSendBtn = false;
        }
      } else {
        this.$toasted.global.error({
          message: 'Wybierz typ pliku oraz wybierz plik',
        });
      }
    },
    async deleteFile(fileItem) {
      try {
        await this.$store.dispatch('client/deleteFile', {
          clientId: this.client._id,
          uuid: fileItem.uuid,
        });
        this.$toasted.global.success({
          message: 'Plik został poprawnie usunięty.',
        });
      } catch (error) {
        console.error(error);
      }
    },
    hrefTo(offerId) {
      return `${config.SOLITAN_OFFER_URL}/${offerId}`;
    },
  },
  async created() {
    await this.getFileByRole();
  },
};
</script>
<style lang="scss" scoped>
@import '../../../scss/_variables.scss';

.disable-overflow {
  overflow-x: hidden;
}

.download-link {
  text-decoration: none;
  color: inherit;
  transition: 0.2s ease-in-out;
  &:hover {
    color: $primary;
  }
}
</style>
