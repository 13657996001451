<template lang="">
  <my-container>
    <loading :loading="loading">
      <v-row>
        <v-col>
          <h3>Informacje dodatkowe</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <p><b>Moc: </b> {{ getInstallationPower }}</p>

          <p><b>Typ instalacji: </b>{{ getInstallationDetails }}</p>

          <p><b>Data montażu: </b>{{ installationDate }}</p>

          <p><b>Numer zamówienia: </b>{{ showOrderNumber }}</p>

          <p><b>Notatka: </b>{{ client.note || '-' }}</p>
        </v-col>
      </v-row>
      <v-row v-if="isOffice">
        <v-col>
          <p><primary-button :onClick="openModal">Edytuj</primary-button></p>
        </v-col>
      </v-row>
      <confirm-modal
        @decline="declineEdit"
        @accept="acceptEdit"
        title="Edytuj dane personalne"
        :open="isModalEditOpen"
        agree="Zapisz"
        disagree="Anuluj"
        :loading="loadingEdit"
      >
        <v-form v-model="isFormValid" ref="form">
          <v-row>
            <v-col>
              <v-text-field
                v-model="editingClient.additionalInstallationInfo.power"
                label="Moc"
                suffix="kW"
                :rules="validator.declaredPowerRules"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-select
                v-model="editingClient.additionalInstallationInfo.installationType"
                :items="installationTypes"
                outlined
                solo
                label="Typ instalacji"
                item-text="nameForUser"
                item-value="value"
                clearable
                @change="onInstallationTypeChange"
              ></v-select>
            </v-col>
          </v-row>
          <v-row v-if="showRoofType">
            <v-col>
              <v-select
                v-model="editingClient.additionalInstallationInfo.roofType"
                :items="roofTypes"
                outlined
                solo
                item-text="nameForUser"
                item-value="value"
                label="Typ dachu"
                clearable
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea v-model="editingClient.note" label="Notatka"></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </confirm-modal>
    </loading>
  </my-container>
</template>
<script>
import { clientGetters } from '../../../../mixins/getters';
import { validator } from '../../../../mixins/validators';
import MyContainer from '../../../MyContainer.vue';
import PrimaryButton from '../../../buttons/PrimaryButton.vue';
import ConfirmModal from '../../../modals/ConfirmModal.vue';
import Loading from '../../../Loading.vue';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import { mapGetters } from 'vuex';
import { formatDate } from '../../../../mixins/formatDate';

export default {
  name: 'ClientAdditional',
  components: { MyContainer, PrimaryButton, ConfirmModal, Loading },
  mixins: [clientGetters, validator, formatDate],
  data() {
    return {
      isModalEditOpen: false,
      editingClient: {
        additionalInstallationInfo: {
          roofType: '',
          installationType: '',
          power: '',
        },
        note: '',
      },
      isFormValid: false,
      loading: false,
      roofTypes: [],
      installationTypes: [],
      showRoofType: false,
      clientRoofTypeObj: {},
      clientInstallationTypeObj: {},
      loadingEdit: false,
      installationTypesObjects: {},
    };
  },
  computed: {
    ...mapGetters('auth', ['isOffice']),
    showOrderNumber() {
      return this.isOffice && this.client.orderNumber ? this.client.orderNumber : '';
    },

    installationDate() {
      return this.client.installationDate ? this.formatDate(this.client.installationDate) : 'Brak';
    },

    getInstallationPower() {
      return this.client.additionalInstallationInfo?.power
        ? `${this.client.additionalInstallationInfo?.power} kW`
        : '-';
    },
    getInstallationDetails() {
      let installationType = this.clientInstallationTypeObj?.nameForUser;
      let roofType = this.clientRoofTypeObj?.nameForUser;

      return `${installationType || 'Brak'} - ${roofType || 'brak'}`;
    },
    ...mapGetters('auth', ['isOffice']),
  },
  methods: {
    setUpEditingClient() {
      const installationType = this.clientInstallationTypeObj;

      this.editingClient = {
        additionalInstallationInfo: {
          installationType: installationType?.value,
          roofType: this.clientRoofTypeObj?.value,
          power: this.client?.additionalInstallationInfo?.power,
        },
        note: this.client.note,
      };

      this.isRoofTypeVisible(installationType);
    },
    handleInstallationNulls() {
      const installationType = this.editingClient?.additionalInstallationInfo?.installationType;
      const roofType = this.editingClient?.additionalInstallationInfo?.roofType;
      if (isEmpty(installationType)) {
        this.editingClient.additionalInstallationInfo.installationType = '';
      }
      if (isEmpty(roofType)) {
        this.editingClient.additionalInstallationInfo.roofType = '';
      }
    },
    async updateClient() {
      try {
        this.loadingEdit = true;

        this.handleInstallationNulls();

        await this.$store.dispatch('client/updateClient', {
          clientId: this.client._id,
          client: this.editingClient,
        });

        this.getUserTypes();
      } catch (e) {
        console.error(e);
      } finally {
        this.loadingEdit = false;
      }
    },
    getUserTypes() {
      this.clientRoofTypeObj = find(this.roofTypes, {
        value: this.client.additionalInstallationInfo?.roofType,
      });

      this.clientInstallationTypeObj = find(this.installationTypes, {
        value: this.client.additionalInstallationInfo?.installationType,
      });
    },
    onInstallationTypeChange() {
      const installationType = this.installationTypes.find(
        (type) => type.value === this.editingClient.additionalInstallationInfo.installationType
      )?.value;

      this.isRoofTypeVisible(installationType);

      this.editingClient.additionalInstallationInfo.roofType = '';
    },
    isRoofTypeDisabled() {
      return (
        this.editingClient.additionalInstallationInfo.installationType !==
        this.installationTypesObjects.PITCHED_ROOF.value
      );
    },

    isRoofTypeVisible(installationType) {
      if (this.isRoofTypeDisabled() || isEmpty(installationType)) {
        this.showRoofType = false;
      } else {
        this.showRoofType = true;
      }
    },
    async openModal() {
      this.setUpEditingClient();

      this.isModalEditOpen = true;
    },
    declineEdit() {
      this.isModalEditOpen = false;
    },
    async acceptEdit() {
      this.$refs.form.validate();
      if (this.isFormValid) {
        await this.updateClient(this.editingClient);
        this.isModalEditOpen = false;
      }
    },

    async init() {
      const roofTypeObjects = await this.$store.dispatch('static/getRoofTypes');
      this.installationTypesObjects = await this.$store.dispatch('static/getInstallationTypes');
      this.roofTypes = Object.values(roofTypeObjects);
      this.installationTypes = Object.values(this.installationTypesObjects);

      this.getUserTypes();
    },
  },
  async created() {
    await this.init();
  },
};
</script>
<style lang=""></style>
