<template>
  <title-link-wrapper title="Wróć do listy klientów" :to="{ name: 'ClientsList' }">
    <template v-slot:right>
      <h1 class="text-right title">Klient: {{ client.fullname || '...' }}</h1>
    </template>
    <loading :loading="loading">
      <v-container>
        <v-row class="my-4">
          <client-status />
        </v-row>
        <v-row class="my-4" v-if="isOffice">
          <assigned-employees />
        </v-row>
        <v-row class="my-4" v-if="showClientOffer">
          <client-offer />
        </v-row>
        <v-row class="my-4" v-if="showClientAssembly">
          <client-assembly />
        </v-row>
        <v-row class="my-4" v-if="showClientFiles">
          <client-files />
        </v-row>
        <v-row class="my-4">
          <client-info-details />
        </v-row>
        <v-row class="my-4">
          <client-chat />
        </v-row>
      </v-container>
    </loading>
  </title-link-wrapper>
</template>
<script>
import TitleLinkWrapper from '../../components/TitleLinkWrapper.vue';
import { mapGetters } from 'vuex';
import Loading from '../../components/Loading.vue';
import AssignedEmployees from '../../components/client/details/ClientAssignedEmployees.vue';
import ClientStatus from '../../components/client/details/ClientStatus.vue';
import ClientInfoDetails from '../../components/client/details/ClientInfoDetails.vue';
import ClientOffer from '../../components/client/details/ClientOffer.vue';
import ClientAssembly from '../../components/client/details/ClientAssembly.vue';
import ClientFiles from '../../components/client/details/ClientFiles.vue';
import ClientChat from '../../components/client/details/ClientChat.vue';

export default {
  name: 'SingleClient',
  components: {
    TitleLinkWrapper,
    Loading,
    AssignedEmployees,
    ClientStatus,
    ClientInfoDetails,
    ClientOffer,
    ClientAssembly,
    ClientFiles,
    ClientChat,
  },
  data() {
    return {
      clientId: '',
      loading: true,
    };
  },
  computed: {
    ...mapGetters('client', ['client', 'clients']),
    ...mapGetters('auth', ['isOffice', 'isTrader', 'isFitter']),
    ...mapGetters('static', ['clientsStatuses']),
    showClientOffer() {
      return (
        this.isOffice ||
        (this.isTrader && this.client.status.value === this.clientsStatuses.TRADER_ASSIGNED.value)
      );
    },
    filesTaderCondition() {
      return (
        (this.isTrader &&
          this.client.status.value === this.clientsStatuses.TRADER_ASSIGNED.value) ||
        (this.isTrader && this.client.status.value === this.clientsStatuses.OFFER_CREATED.value) ||
        (this.isTrader &&
          this.client.status.value === this.clientsStatuses.PROPOSAL_GENERATED.value)
      );
    },
    showClientAssembly() {
      return this.isOffice || this.isFitter;
    },
    filesFitterCondition() {
      if (this.isFitter && this.clientsStatuses)
        return (
          this.isFitter &&
          this.client.status?.value === this.clientsStatuses?.WAITING_FOR_ASSEMBLY?.value
        );
      else return '';
    },
    showClientFiles() {
      return this.isOffice || this.filesTaderCondition || this.filesFitterCondition;
    },
  },
  methods: {
    async fetchClient() {
      this.loading = true;
      try {
        await this.$store.dispatch('client/getClientById', {
          clientId: this.clientId,
        });
        await this.$store.dispatch('static/getClientStatuses');
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    async init() {
      await this.fetchClient();
    },
  },
  async activated() {
    this.clientId = this.$route.params.clientId;
    await this.init();
  },
};
</script>
<style lang=""></style>
