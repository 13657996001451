import { mapGetters } from 'vuex';

export const clientGetters = {
  computed: {
    ...mapGetters('client', ['client', 'clients']),
    trader() {
      return this.client?.trader?.fullname || 'Brak';
    },
    fitter() {
      return this.client?.fitter?.fullname || 'Brak';
    },
  },
};

export const staticGetters = {
  computed: {
    ...mapGetters('static', [
      'clientsStatuses',
      'roofTypes',
      'installationTypes',
      'clientFileTypes',
    ]),
  },
};

export const fileTypesByRoleGetter = {
  computed: {
    ...mapGetters('static', ['roleFileTypes']),
  },
};
