<template lang="">
  <my-container>
    <v-row>
      <v-col><h3>Przypisani pracownicy</h3></v-col></v-row
    >
    <v-row>
      <v-col>
        <p>Handlowiec: {{ trader }}</p>
        <p>Instalator: {{ fitter }}</p>
      </v-col>
      <v-col>
        <p>Prowizja: {{ provision }} %</p>
        <p>Wartość montażu: {{ assemblyValue }} zł</p>
      </v-col>
      <v-col class="text-right">
        <primary-button v-if="isOffice" :onClick="openModal">Edytuj</primary-button>
      </v-col>
    </v-row>
    <confirm-modal
      @decline="declineEdit"
      @accept="acceptEdit"
      title="Przypisz pracowników do klienta"
      :open="isModalEditOpen"
      agree="Zapisz"
      disagree="Anuluj"
      :loading="btnEmployeesLoading"
      modalWidth="700"
    >
      <loading :loading="loadingEmployees">
        <v-row>
          <v-col class="pr-6">
            <v-select
              v-model="pickedTraderId"
              :items="traders"
              label="Handlowiec"
              item-text="fullName"
              item-value="_id"
            ></v-select>
            <v-text-field
              label="Opcjonalna prowizja %"
              v-model="tempProvision"
              :disabled="!pickedTraderId"
            ></v-text-field>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col class="pl-6">
            <v-select
              v-model="pickedFitterId"
              :items="fitters"
              label="Instalator"
              item-text="fullName"
              item-value="_id"
            ></v-select>
            <v-text-field
              label="Wartość montażu"
              v-model="tempAssemblyValue"
              :disabled="!pickedFitterId"
            ></v-text-field>
          </v-col>
        </v-row>
      </loading>
    </confirm-modal>
  </my-container>
</template>
<script>
import { clientGetters } from '../../../mixins/getters';
import { mapGetters } from 'vuex';
import PrimaryButton from '../../../components/buttons/PrimaryButton.vue';
import ConfirmModal from '../../../components/modals/ConfirmModal.vue';
import MyContainer from '../../../components/MyContainer.vue';
import Loading from '../../Loading.vue';

export default {
  name: 'ClientAssignedEmployees',
  mixins: [clientGetters],
  components: {
    PrimaryButton,
    ConfirmModal,
    Loading,
    MyContainer,
  },
  data() {
    return {
      isModalEditOpen: false,
      fitters: [],
      traders: [],
      pickedFitterId: undefined,
      pickedTraderId: undefined,
      loadingEmployees: true,
      btnEmployeesLoading: false,
      tempProvision: undefined,
      tempAssemblyValue: undefined,
    };
  },
  computed: {
    provision: {
      get() {
        return this.client.provision;
      },
    },
    assemblyValue: {
      get() {
        return this.client.assemblyValue;
      },
    },
    ...mapGetters('auth', ['isOffice', 'isTrader', 'isFitter'], 'client'),
  },
  methods: {
    openModal() {
      this.isModalEditOpen = true;
      this.fetchData();
    },
    declineEdit() {
      this.isModalEditOpen = false;
    },
    async fetchData() {
      this.loadingEmployees = true;
      try {
        this.traders = await this.$store.dispatch('user/getTraders');
        this.fitters = await this.$store.dispatch('user/getFitters');
      } catch (e) {
        this.error = e;
      } finally {
        this.loadingEmployees = false;
      }
    },
    async assignProvision() {
      if (this.tempProvision) {
        try {
          await this.$store.dispatch('client/assignProvision', {
            clientId: this.client._id,
            provision: this.tempProvision,
          });
        } catch (e) {
          this.error = e;
        }
      }
    },
    async assignAssemblyValue() {
      if (this.tempAssemblyValue) {
        try {
          await this.$store.dispatch('client/assignAssemblyValue', {
            clientId: this.client._id,
            assemblyValue: this.tempAssemblyValue,
          });
        } catch (e) {
          this.error = e;
        }
      }
    },
    traderChanged() {
      return this.client.traderId !== this.pickedTraderId;
    },
    fitterChanged() {
      return this.client.fitterId !== this.pickedFitterId;
    },
    async acceptEdit() {
      if (this.pickedFitterId || this.pickedTraderId) {
        this.btnEmployeesLoading = true;
        let updateOptions = {};
        if (this.traderChanged())
          updateOptions = { ...updateOptions, traderId: this.pickedTraderId };
        if (this.fitterChanged())
          updateOptions = { ...updateOptions, fitterId: this.pickedFitterId };

        try {
          await this.$store.dispatch('client/assignEmployees', {
            clientId: this.client._id,
            ...updateOptions,
          });
          this.assignProvision();
          this.assignAssemblyValue();
          this.isModalEditOpen = false;
        } catch (e) {
          this.error = e;
        } finally {
          this.btnEmployeesLoading = false;
        }
      } else {
        this.$toasted.global.error({ message: 'Wybierz pracownika' });
      }
    },
  },
  created() {
    this.pickedTraderId = this.client.trader?._id;
    this.pickedFitterId = this.client.fitter?._id;
    this.tempProvision = this.client.provision;
    this.tempAssemblyValue = this.client.assemblyValue;
  },
};
</script>
<style lang=""></style>
