<template>
  <v-container class="ma-0 pa-0">
    <v-row class="ma-0 pa-0">
      <v-col cols="12" md="6" class="ml-md-0 pl-md-0">
        <client-personal></client-personal>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" md="6" class="mr-md-0 pr-md-0">
        <client-additional></client-additional>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import ClientPersonal from './info/ClientPersonal.vue';
import ClientAdditional from './info/ClientAdditional.vue';
export default {
  name: 'ClientInfoDetails',
  components: { ClientPersonal, ClientAdditional },
};
</script>
<style lan ClientPersonalg=""></style>
