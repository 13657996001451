<template lang="">
  <v-container class="ma-0 pa-0">
    <my-container>
      <v-row>
        <v-col cols="12"
          ><h2>
            Status:
            <span class="text-uppercase ml-8">
              <v-chip :color="client.status.color || 'primary'" large label
                ><span class="white--text pa-4 font-weight-regular headline">{{
                  client.status.nameForUser ? client.status.nameForUser : 'Błąd'
                }}</span></v-chip
              ></span
            >
          </h2></v-col
        ></v-row
      >
      <v-row>
        <v-col cols="12">
          {{ returnToolTipText() }}
        </v-col>
      </v-row>
      <v-row v-if="isOffice">
        <v-col>
          <v-checkbox
            dense
            label="Wyślij e-mail przy zmianie statusu"
            v-model="client.sendEmail"
            @change="toggleSendEmail"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="client.lastStatusLog" cols="12" class="grey--text text-darken-1 overline">
          <p class="text-uppercase" v-if="isOffice">
            {{ client.lastStatusLog.messageForOffice }}
            {{ formatDateWithHour(client.lastStatusLog.createdAt) }}
          </p>
          <p class="text-uppercase" v-else>
            {{ client.lastStatusLog.message }}
            {{ formatDateWithHour(client.lastStatusLog.createdAt) }}
          </p></v-col
        >
      </v-row>
    </my-container>
    <v-container class="mt-4 mx-0 px-0 transparent" v-if="showStatusContainer">
      <v-row class="mx-0 px-0">
        <v-col cols="12" sm="8" md="10" class="mx-0 px-0">
          <v-select
            v-if="isTrader"
            solo
            v-model="solitanSelectValue"
            :items="solitanOfferStatusObj"
            item-text="nameForUser"
            item-value="value"
          ></v-select>

          <v-select
            v-else
            solo
            v-model="pickedStatus"
            :items="clientsStatusesArr"
            item-text="nameForUser"
            item-value="value"
            label="Status"
            menu-props="auto"
          >
            <template v-slot:item="{ item }">
              <span class :class="isNextStatus(item) ? 'light-green--text': ''">
              {{ selectNameForUser(item) }}
              </span>
            </template>
          </v-select>
        </v-col>
        <v-col cols="12" sm="4" md="2" class="text-right">
          <primary-button :onClick="setStatus">Aktualizuj</primary-button></v-col
        >
      </v-row>
      <confirm-modal
        @decline="declineEdit"
        @accept="acceptEdit"
        title="Przypisz pracowników do klienta"
        :open="isModalEditOpen"
        agree="Zapisz"
        disagree="Anuluj"
        :loading="btnOrderNumberModalLoading"
      >
        <v-row>
          <v-col>
            <v-text-field label="Numer zamówienia" v-model="orderNumber"></v-text-field>
          </v-col>
        </v-row>
      </confirm-modal>
    </v-container>
  </v-container>
</template>
<script>
import MyContainer from '../../../components/MyContainer.vue';
import { mapGetters } from 'vuex';
import { clientGetters, staticGetters } from '../../../mixins/getters';
import PrimaryButton from '../../buttons/PrimaryButton.vue';
import find from 'lodash/find';
import ConfirmModal from '../../modals/ConfirmModal.vue';
import { formatDateWithHour } from '../../../mixins/formatDate';
import { isEmpty } from 'lodash';

export default {
  name: 'ClientStatus',
  components: { MyContainer, PrimaryButton, ConfirmModal },
  mixins: [clientGetters, staticGetters, formatDateWithHour],
  data() {
    return {
      pickedStatus: '',
      btnStatusLoading: false,
      orderNumber: undefined,
      btnOrderNumberModalLoading: false,
      isModalEditOpen: false,
      minKWforSingleFileUpload: 6,
      solitanSelectValue: 'OFFER_CREATED',
    };
  },
  watch: {
    'client.status.value': function(){
      this.pickedStatus = this.client.status.value;
    }
  },
  computed: {
    showStatusContainer() {
      return this.isOffice || this.isSolitanTraderStatusChange();
    },
    generatedInSolitan() {
      return !isEmpty(this.client.solitanOffer);
    },
    solitanOfferStatusObj() {
      return this.clientsStatusesArr.filter(
        (item) => item.value === this.clientsStatuses.OFFER_CREATED.value
      );
    },
    clientsStatusesArr() {
      return this.clientsStatuses ? Object.values(this.clientsStatuses) : [];
    },
    clientStatusesOrderedInLine() {
      return this.clientsStatusesArr.map((status) => status.value);
    },
    nextStatusValue() {
      const nextIndex = this.clientStatusesOrderedInLine.indexOf(this.client.status.value) + 1;
      return this.clientStatusesOrderedInLine[nextIndex];
    },
    ...mapGetters('auth', ['isOffice', 'isTrader', 'isFitter']),
  },
  methods: {
    isNextStatus(selectItem){
      return selectItem.value === this.nextStatusValue;
    },
    selectNameForUser(selectItem){
      if(this.isNextStatus(selectItem)) return `${selectItem.nameForUser}  -  następny status`
      return selectItem.nameForUser;        
    },
    async toggleSendEmail() {
      await this.$store.dispatch('client/updateSendEmail', {
        clientId: this.client._id,
        sendEmail: this.client.sendEmail,
      });
    },
    returnToolTipText() {
      const power = this.client.additionalInstallationInfo.power;
      if (
        !!power &&
        power > this.minKWforSingleFileUpload &&
        this.client.status.value === this.clientsStatuses.FV_PAID.value
      ) {
        return 'Moc instalacji powyżej 6kW, biuro dołącza do plików klienta wniosek do energetyki oraz wniosek PPOŻ';
      } else
        return (
          this.client.status.textForUser
        );
    },
    openModal() {
      this.isModalEditOpen = true;
    },
    declineEdit() {
      this.isModalEditOpen = false;
    },
    async acceptEdit() {
      if (this.orderNumber) {
        this.btnOrderNumberModalLoading = true;
        try {
          await this.$store.dispatch('client/assignOrderNumber', {
            clientId: this.client._id,
            orderNumber: this.orderNumber,
          });
          this.setStatus();
          this.isModalEditOpen = false;
        } catch (e) {
          this.error = e;
        } finally {
          this.orderNumber = undefined;
          this.btnOrderNumberModalLoading = false;
        }
      } else {
        this.$toasted.global.error({ message: 'Wpisz numer zamówienia' });
      }
    },
    isSolitanTraderStatusChange() {
      return (
        this.isTrader &&
        this.generatedInSolitan &&
        this.client.status.value === this.clientsStatuses.TRADER_ASSIGNED.value
      );
    },
    setStatus() {
      if (this.client.status.value === this.clientsStatuses.FV_FINAL.value &&  !this.client.additionalInstallationInfo.power) {
         this.$toasted.global.error({
          message: 'Musisz dodać moc instalacji w danych klienta',
        });
        return
      }
      if (this.pickedStatus || this.isSolitanTraderStatusChange()) {
        this.btnStatusLoading = true;
        if (this.pickedStatus === 'ORDER_CREATED' && !this.orderNumber) {
          this.openModal();
        } else {
          try {
            const statusObj = find(this.clientsStatusesArr, {
              value: this.isSolitanTraderStatusChange()
                ? this.solitanSelectValue
                : this.pickedStatus,
            });
            this.$store.dispatch('client/updateStatus', {
              clientId: this.client._id,
              status: statusObj,
            });
            this.fetchStatuses();
            this.pickedStatus = this.client.status.value;
          } catch (e) {
            this.btnStatusLoading = false;
            console.error(e);
          } finally {
            this.btnStatusLoading = false;
          }
        }
      } else {
        this.$toasted.global.error({
          message: 'Musisz wybrać status aby go zaktualizować',
        });
      }
    },
    async fetchStatuses() {
      await this.$store.dispatch('static/getClientStatuses');
    },
  },
  created(){
    this.fetchStatuses();
    this.pickedStatus = this.client.status.value;
  },
};
</script>
<style lang=""></style>
